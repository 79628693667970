@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

$title-font: 'Rubik', sans-serif;
$text-font: 'Rubik', sans-serif;
$number-font: 'Rubik', sans-serif;

h1, h2, h3, h4, h5, h6 {
    font-family: $title-font; }

html, body {
    font-family: $text-font; }

.titleFont {
    font-family: $title-font; }

.textFont {
    font-family: $text-font; }

.numberFont {
    font-family: $number-font; }
