@import "colors";
@import "fonts";

.draft-table {
    margin: 0;

    thead th {
        background: $lt-blue;
        border-color: $outline;
        border-bottom-width: 1px;
        font-size: 14px;
        font-weight: 600;
        color: #838389;
        padding: 1rem;
        font-family: "Mulish", sans-serif; }

    td {
        border-color: $outline;
        font-size: 14px;
        font-weight: 400;
        padding: .75rem 1rem;
        color: #44454B;
        vertical-align: inherit;
        border: none;
        font-family: "Mulish", sans-serif; }

    tr {
        border: 1px solid $outline;

        a {
            color: #44454B;
            display: inline-block;
            position: relative;
            left: 5px; } }

    tbody tr:last-child {
        td:first-child {
            border-bottom-left-radius: 80px; }
        td:last-child {
            border-bottom-right-radius: 80px; } } }

.documentIcon {
    margin-bottom: 5px;
    margin-right: 5px; }
